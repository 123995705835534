/* Distilled from: https://github.com/coolaj86/unibabel-js/ */

const base64ToUtf8 = (b64: string): string =>
    decodeURIComponent(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.atob(b64).replace(/(.)/g, (_m: any, p: any, code: any) => {
            // eslint-disable-next-line no-param-reassign
            code = p.charCodeAt(0).toString(16).toUpperCase();
            return '%' + (code.length < 2 ? '0' + code : code);
        }),
    );

const utf8ToBase64 = (str: string): string =>
    window.btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (_match: any, p1: any) => String.fromCharCode(parseInt(p1, 16)),
        ),
    );

const encodeUrlSafeBase64 = (str: string): string =>
    utf8ToBase64(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

const decodeUrlSafeBase64 = (str: string): string =>
    base64ToUtf8(str.replace(/-/g, '+').replace(/_/g, '/'));

const makeSafeForUrl = (val: unknown): string =>
    encodeUrlSafeBase64(JSON.stringify(val));

const unmakeSafeForUrl = (val: string): unknown =>
    JSON.parse(decodeUrlSafeBase64(val));

export const UniBabel = {
    base64ToUtf8,
    utf8ToBase64,
    makeSafeForUrl,
    unmakeSafeForUrl,
};
