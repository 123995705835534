import { hasProperty, isNumber } from '@extrahop/type-utils';

import { ApiClient, RequestConfig } from './ApiClient';

/** An incomplete interface for capture metric responses from the bridge */
type BridgeResponse<T> =
    | { result: { stats: T[] } }
    | { error: { message: string } };

interface BridgeBatchResponse<T> {
    result: BridgeResponse<T>[] | 'again';
}

/**
 * A bridge query response that indicates that the client must
 * make subsequent requests in order to receive all of the data.
 * This is used for cases where multiple EDAs must be queried.
 */
interface MultiChunkResponse {
    result: {
        xid: number;
        num_results: number;
    };
}

const MultiChunkResponse = {
    guard: (x: unknown): x is MultiChunkResponse =>
        hasProperty(x, 'result') &&
        hasProperty(x.result, 'xid', isNumber) &&
        hasProperty(x.result, 'num_results', isNumber),
};

const getStats = <T>(response: BridgeResponse<T>): T[] => {
    if (hasProperty(response, 'error')) {
        console.error(
            'Query failed, no valid metric response',
            response.error.message,
        );
        // Return an empty array and continue instead of throwing, as we have an
        // empty chart graphic specifically for this case.
        // See commit 66e2cc8f
        return [];
    }

    if (!hasProperty(response.result, 'stats')) {
        console.error('Response does not contain any stats');
        return [];
    }

    // we have the stats, return those directly
    return response.result.stats;
};

const getMultiChunkStats = async <T>(
    transactionId: number,
    numResults: number,
): Promise<T[]> => {
    const responses: BridgeResponse<T>[] = [];
    let retryAttempts = 0;

    while (responses.length < numResults) {
        const res = await ApiClient.post<BridgeBatchResponse<T>>(
            'bridge.getExStatsNext',
            { xid: transactionId, batch: true },
        ).then(({ result }) => result);

        if (res !== 'again') {
            responses.push(...res);
        }

        // Log retry count every 10 retries to prevent noisy warnings
        if (retryAttempts > 0 && retryAttempts % 10 === 0) {
            console.warn(
                'Attempts to retry multi-chunk bridge request:',
                retryAttempts,
            );
        }
        retryAttempts++;
    }
    return responses.flatMap(getStats);
};

/**
 * Queries the bridge with the provided `method`, then calls
 * `'bridge.getExStatsNext'` as many times as needed to collect all the
 * results.
 *
 * This function is appropriate if you can afford to wait for all the subsequent
 * calls to resolve. If not, a new function that lets you consume the results
 * incrementally (e.g. via an `AsyncIterable`) would need to be exposed.
 *
 * The stat type `StatType` is expected to be passed in, since the responses
 * vary widely depending on the query.
 *
 */
export const getExStatsAll = async <StatType>(
    method:
        | 'bridge.getExStats'
        | 'bridge.getExStatsGroup'
        | 'bridge.getExStatsTotal',
    args: unknown,
    config?: RequestConfig,
): Promise<StatType[]> => {
    const firstResponse = await ApiClient.post<
        MultiChunkResponse | BridgeResponse<StatType>
    >(method, args, config);
    const response = MultiChunkResponse.guard(firstResponse)
        ? await getMultiChunkStats<StatType>(
              firstResponse.result.xid,
              firstResponse.result.num_results,
          )
        : getStats(firstResponse);

    return response;
};
